<script>
import Layout from "./../layout/main";
import PageHeader from "@/components/static/page-header";
import appConfig from "@/app.config";
import Swal from 'sweetalert2';
export default {
  page: {
    title: "Dashboard",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
  },

  data() {
    return {
        title: "Settings",
        settings: [],
        content : {}
    }
    
  },
  mounted() {
    this.fetchSettings();
  },
    methods: {
        fetchSettings() {
            this.$axios.get('/settings')
            .then(response => {
                let data = response.data;
                this.settings = data.payload;
            })
            .catch(error => {
                self.handleErrorResponse(error.response, "error");
            })
            .finally(function () {
                
            });
        },

        UpdatePushNotification(setting) {
            this.$axios.post('/settings/update',setting)
            .then(response => {
               Swal.fire("Success!", response.data.message, "success");
               this.$router.push('/app_update');
            })
            .catch(error => {
                this.handleErrorResponse(error.response, "error");
            })
            .finally(function () {
                
            });
        },
        getContent(key){
            if(key === 'notification_pushNotification'){
                this.content.heading = 'Push Notification';
                this.content.body = "Push notifications are small, pop-up messages sent to a user’s device by a mobile app that appear even when the app isn't open. These notifications are designed to grab attention and can convey reminders, updates, promotions, and more.";
            }
            return this.content;
        }
    },
  middleware: "authentication",
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" />
   
    <div class="row">
      <div class="col-12">
        <div class="d-grid gap-2 grid_inline">
          <div class="card rounded box-shadow px-3 py-3">
            <div class="row align-items-center" v-for="(setting,index) in settings" :key="index">
                <div class="col-6">
                    <div class="" >
                        <h6 class="fw-bolder"> {{ getContent(setting.key).heading }}</h6>
                        <p>{{ getContent(setting.key).body}}</p>
                    </div>
                </div>
                <div class="col-4">
                </div>
                <div class="col-2">
                    <div class="form-check form-switch mb-2 f-20">
                        <input class="form-check-input" type="checkbox" :checked="setting.value === 1" 
                        v-model="setting.value" :true-value="1" :false-value="0" @change="UpdatePushNotification(setting)"/>
                    </div>
                </div>
                <hr>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
